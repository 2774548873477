console.log("loading latest...");

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

jQuery(document).ready(function ($) {
  window.dataLayer = window.dataLayer || [];

  var allRooms = $(".trip-planner-single-lodging-wrapper");
  var loader = $(".trip-planner-loader");

  // =================
  // Open Trip Planner
  // =================
  if (!$("body").hasClass("page-id-5640")) {
    $(
      '.bd-open-trip-planner, a[href="http://trip-planner/"], a[href="https://trip-planner/"], a[href="https://trip-planner"], a[href="https://trip-planner"]'
    ).on("click", function (e) {
      e.preventDefault();
      console.log("click trip planner");
      $("#bd-trip-planner").addClass("open");
      $("html").addClass("noscroll");
      resetToStep1();
    });
  }

  function resetToStep1() {
    $(".trip-planner-inner-wrapper-active").removeClass("trip-planner-inner-wrapper-active");
    $(".trip-planner-step-1-wrapper").addClass("trip-planner-inner-wrapper-active");
  }

  $(".disclaimer-wrapper").hover(
    function () {
      $(".disclaimer-text").addClass("hovered");
    },
    function () {
      $(".disclaimer-text").removeClass("hovered");
    }
  );
  // Close Trip Planner
  $(".bd-close-trip-planner").on("click", function (e) {
    e.preventDefault();
    $("#bd-trip-planner").removeClass("open");
    $("html").removeClass("noscroll");
  });
  // Open Availability Calendar
  $(".bd-trip-planner-calendar-popup-open").on("click", function (e) {
    e.preventDefault();
    $(".bd-trip-planner-calendar-popup").addClass("open");
  });
  // Close Availability Calendar
  $(".bd-trip-planner-calendar-popup-close").on("click", function (e) {
    e.preventDefault();
    $(".bd-trip-planner-calendar-popup").removeClass("open");
  });
  // Previous Step Button
  $(".trip-planner-prev").on("click", function (e) {
    e.preventDefault();
    console.log("advance to the previous TP slide");

    $(this).closest(".trip-planner-inner-wrapper").removeClass("trip-planner-inner-wrapper-active");

    if ($(this).closest(".trip-planner-step-3-wrapper").length > 0) {
      // activate step 2 (skipping 2b)
      $(this)
        .closest(".trip-planner-inner-wrapper")
        .prev(".trip-planner-inner-wrapper")
        .prev(".trip-planner-inner-wrapper")
        .addClass("trip-planner-inner-wrapper-active");
    } else if ($(this.closest(".trip-planner-step-2b-form-wrapper"))) {
      handleSearchAgain();
      $(this)
        .closest(".trip-planner-inner-wrapper")
        .prev(".trip-planner-inner-wrapper")
        .addClass("trip-planner-inner-wrapper-active");
    } else if ($(this.closest(".trip-planner-step-1b-wrapper"))) {
      $(this).closest(".trip-planner-inner-wrapper").removeClass("trip-planner-inner-wrapper-active");
      $(".trip-planner-step-2-wrapper").addClass("trip-planner-inner-wrapper-active");
    } else {
      $(this)
        .closest(".trip-planner-inner-wrapper")
        .prev(".trip-planner-inner-wrapper")
        .addClass("trip-planner-inner-wrapper-active");
    }
  });
  // Search Again
  $(".trip-planner-search-again").on("click", function (e) {
    e.preventDefault();
    handleSearchAgain();
  });
  // =================
  // Next Buttons
  // =================
  // They all have the same class so we do a series of if checks to figure out which one and do the appropriate thing.
  $(".trip-planner-next").on("click", function (e) {
    event.preventDefault();
    if ($(this).hasClass("trip-planner-step-1-next")) {
      console.log("step 1 next clicked");
      if ($("#FirstName").valid() && $("#LastName").valid() && $("#EmailAddress").valid()) {
        console.log("step 1 validation passed");
        $(this).closest(".trip-planner-inner-wrapper").removeClass("trip-planner-inner-wrapper-active");
        $(this)
          .closest(".trip-planner-inner-wrapper")
          .next(".trip-planner-inner-wrapper")
          .addClass("trip-planner-inner-wrapper-active");
      }
    } else if ($(this).hasClass("trip-planner-step-1b-next")) {
      // This step doesn't need validation since the next button only appears if a selection is made.
      $(this).closest(".trip-planner-inner-wrapper").removeClass("trip-planner-inner-wrapper-active");
      $(this)
        .closest(".trip-planner-inner-wrapper")
        .next(".trip-planner-inner-wrapper")
        .addClass("trip-planner-inner-wrapper-active");
    } else if ($(this).hasClass("trip-planner-step-2-next")) {
      // simulate no availability
      // $(".trip-planner-loader").hide();
      // $(".trip-planner-step-2b-form-wrapper").hide();
      // handleNoAvailability();

      if ($("#CheckInDate").valid() && $("#CheckOutDate").valid()) {
        var needsLodging = $('input[name="lodging_answers"]:checked').val() === "yes";

        if ($(".synxis-enabled").length && needsLodging) {
          toggleLodgingListVisibility(false);
          filterRoomsWithSynxisApiData();
        } else {
          loader.hide();
        }

        $(this).closest(".trip-planner-inner-wrapper").removeClass("trip-planner-inner-wrapper-active");

        if (needsLodging) {
          $(this)
            .closest(".trip-planner-inner-wrapper")
            .next(".trip-planner-inner-wrapper")
            .addClass("trip-planner-inner-wrapper-active");
        } else {
          // They said no lodging is needed, so skip to step 3
          $(".trip-planner-step-3-wrapper").addClass("trip-planner-inner-wrapper-active");
        }
      }
    } else if ($(this).hasClass("trip-planner-step-2b-next")) {
      // This step doesn't need validation, all fields are optional.
      $(this).closest(".trip-planner-inner-wrapper").removeClass("trip-planner-inner-wrapper-active");
      $(this)
        .closest(".trip-planner-inner-wrapper")
        .next(".trip-planner-inner-wrapper")
        .addClass("trip-planner-inner-wrapper-active");

      if ($(this).hasClass("trip-planner-next-flexible")) {
        setNeedLodging(true);
      }

      if ($(this).hasClass("trip-planner-next-just-golf")) {
        setNeedLodging(false);
      }
    } else if ($(this).hasClass("trip-planner-step-3-next")) {
      // This step doesn't need validation, all fields are optional.
      $(this).closest(".trip-planner-inner-wrapper").removeClass("trip-planner-inner-wrapper-active");
      $(this)
        .closest(".trip-planner-inner-wrapper")
        .next(".trip-planner-inner-wrapper")
        .addClass("trip-planner-inner-wrapper-active");

      if ($(this).hasClass("trip-planner-next-flexible")) {
        setNeedLodging(true);
      }

      if ($(this).hasClass("trip-planner-next-just-golf")) {
        setNeedLodging(false);
      }
    }
  });
  // =================
  // Radio button click on step 1b
  // =================
  $(".trip-planner-radio-select").on("click", function (e) {
    e.preventDefault();
    // console.log("radio click, value is " + $(this).siblings("input").val());
    $(this).siblings("input").prop("checked", true);
    // Add the class on all the buttons so they're transparent
    $(".trip-planner-radio-select").addClass("btn-transparent");
    // Then remove it from the clicked one so it is solid (e.g. selected)
    $(this).removeClass("btn-transparent");
    // Remove the 'selected' class from the boxes
    $(".trip-planner-select-item-wrapper").removeClass("selected");
    // And add it to the clicked one.
    $(this).closest(".trip-planner-select-item-wrapper").addClass("selected");
    // Show the next button
    $(".trip-planner-step-1b-next").css("display", "inline-block");
    // Hide fields  on Step 2 that are no longer relevant.
    if ($(this).siblings("input").val() == "yes") {
      console.log("Needs lodging: yes");
      $("#rooms").val(1);
      $("#beds").val(1);
      $("#rooms").parent().css("display", "block");
      $("#beds").parent().css("display", "block");
      $(".trip-planner-step-2-text.golf").hide();
      $(".trip-planner-step-2-text.lodging").show();

      console.log("show availability button");
      $(".bd-trip-planner-calendar-popup-open").show();

      $(".label-check-in").text("Check In ");
      $(".label-check-out").text("Check Out ");
    } else {
      console.log("Needs loding: no");
      $("#rooms").val(0);
      $("#beds").val(0);
      $("#rooms").parent().css("display", "none");
      $("#beds").parent().css("display", "none");
      $(".trip-planner-step-2-text.golf").show();
      $(".trip-planner-step-2-text.lodging").hide();

      console.log("Hiding availability button");
      $(".bd-trip-planner-calendar-popup-open").hide();

      $(".label-check-in").text("Arrival ");
      $(".label-check-out").text("Departure ");
    }
  });
  // =================
  // NEW DATE PICKER
  // =================
  function setDatepickerPos(input, inst) {
    var rect = input.getBoundingClientRect();

    // use 'setTimeout' to prevent effect overridden by other scripts
    setTimeout(function () {
      var scrollTop = $("body").scrollTop();
      let css = {
        top: rect.bottom - input.offsetHeight - scrollTop,
        zIndex: 1000,
      };

      if (window.navigator.userAgent.indexOf("Mobile") > -1) {
        css = {
          bottom: rect.bottom - input.offsetHeight - scrollTop,
          zIndex: 1000,
          height: 260,
        };
      }

      inst.dpDiv.css(css);
    }, 0);
  }

  $("#CheckInDate").datepicker({
    defaultDate: 0,
    dateFormat: "mm-dd-yy",
    altFormat: "yy-mm-dd",
    minDate: 0,
    maxDate: "+3y",
    beforeShow: function (input, inst) {
      setDatepickerPos(input, inst);
    },
    onSelect: function (selectedDate) {
      $("#CheckOutDate").datepicker("option", "minDate", selectedDate);
      setTimeout(function () {
        $("#CheckOutDate").datepicker("show");
      }, 16);
    },
  });
  $("#CheckOutDate").datepicker({
    defaultDate: +2,
    dateFormat: "mm-dd-yy",
    altFormat: "yy-mm-dd",
    minDate: +1,
    maxDate: "+3y",
    beforeShow: function (input, inst) {
      setDatepickerPos(input, inst);
    },
  });

  // Apply style to checkbox when clicked
  $(".trip-planner-single-lodging-wrapper .trip-planner-choice-label input").on("change", function () {
    $(this).closest("label").toggleClass("checked", $(this).is(":checked"));
  });

  // =================
  // Review Request
  // =================
  $(".trip-planner-initialize-review").on("click", function (e) {
    console.log("We should be initializing the review data");
    $(this).closest(".trip-planner-inner-wrapper").removeClass("trip-planner-inner-wrapper-active");
    $(this)
      .closest(".trip-planner-inner-wrapper")
      .next(".trip-planner-inner-wrapper")
      .addClass("trip-planner-inner-wrapper-active");
    e.preventDefault();
    var course_type_data = [];
    $("input.course_type:checked").each(function (index, element) {
      course_type_data.push($(element).val());
    });
    if (course_type_data.length === 0) {
      course_type_data.push("No Courses Requested");
    }
    var lodging_type_data = [];
    $("input.lodging_type_picker:checked").each(function (index, element) {
      lodging_type_data.push($(element).val());
    });
    if (lodging_type_data.length === 0) {
      lodging_type_data.push("No Lodging Types Requested");
    }
    var golf_request_data = [];
    $('.golf-yes-no-wrapper input[type="radio"]:checked').each(function (index, element) {
      golf_request_data.push($(element).attr("name") + ":" + $(element).val());
    });
    var formData = {
      send_to: $("#send_to").val(),
      FirstName: $("#FirstName").val(),
      LastName: $("#LastName").val(),
      EmailAddress: $("#EmailAddress").val(),
      optin: $("#opt-in").val(),
      CheckInDate: $("#CheckInDate").val(),
      CheckOutDate: $("#CheckOutDate").val(),
      Adults: $("#Adults").val(),
      rooms: $("#rooms").val(),
      beds: $("#beds").val(),
      lodging_answers: $("input[name='lodging_answers']:checked").val(),
      lodging_type: lodging_type_data.toString(),
      course_type: course_type_data.toString(),
      golf_request: golf_request_data.toString(),
      HomePhone: $("#HomePhone").val(),
      Country: $("#Country").val(),
      ZipCode: $("#ZipCode").val(),
      occasion: $("#occasion").val(),
      time_of_day: $("[name='time_of_day']:selected").val(),
    };
    console.log("🚀 ~ file: trip_planner.js ~ line 351 ~ formData", formData);
    // Now replace the fields in the review area with the entered data.
    $(".summary-full-name span").text(formData["FirstName"] + " " + formData["LastName"]);
    $(".summary-email span").text(formData["EmailAddress"]);
    if (formData["CheckInDate"] != "" && formData["CheckInDate"] != "") {
      if (formData["CheckInDate"] != "") {
        $(".summary-checkin span").text(formData["CheckInDate"]);
      }
      if (formData["CheckOutDate"] != "") {
        $(".summary-checkout span").text(formData["CheckOutDate"]);
      }
    }
    $(".summary-rooms span").text(formData["rooms"] + " Room(s)");
    $(".summary-beds span").text(formData["beds"] + " Bed(s)");
    $(".summary-guests span").text(formData["Adults"] + " Guest(s)");

    if (formData["lodging_answers"] === "no") {
      $(".summary-lodging_answers span").text("No, I will not need lodging");
    } else {
      $(".summary-lodging_answers span").text("Yes, I will need lodging");
    }

    // Clear the existing text.
    $(".summary-data-lodging_type").text("");
    if (lodging_type_data.length != 0) {
      $.each(lodging_type_data, function (index, val) {
        $(".summary-data-lodging_type").append('<li class="summary-data-single"><span>' + val + "</span></li>");
      });
    } else {
      $(".summary-data-lodging_type").append(
        '<div class="summary-data-single"><span>No preferred lodging</span></div>'
      );
    }

    // hide fields for golf only
    if (formData["lodging_answers"] === "no") {
      $(".summary-rooms").closest("li").hide();
      $(".summary-beds").closest("li").hide();
      $(".summary-data-lodging_type").closest("li").hide();
    } else {
      $(".summary-rooms").closest("li").show();
      $(".summary-beds").closest("li").show();
      $(".summary-data-lodging_type").closest("li").show();
    }

    // Clear the existing text.
    $(".summary-data-course_type").text("");
    if (course_type_data.length != 0) {
      $.each(course_type_data, function (index, val) {
        $(".summary-data-course_type").append(
          '<li class="summary-data-single none-chosen"><span>' + val + "</span></li>"
        );
      });
    } else {
      $(".summary-data-course_type").append(
        '<div class="summary-data-single none-chosen"><span>No preferred courses</span></div>'
      );
    }

    $(".summary-data-tee-time-arrival span").text(
      capitalizeFirstLetter(
        golf_request_data[0].replace("tee_time_on_arrival_day?-yes:", "").replace("tee_time_on_arrival_day?-yes", "Yes")
      )
    );
    $(".summary-data-tee-time-departure span").text(
      capitalizeFirstLetter(
        golf_request_data[1]
          .replace("tee_time_on_departure_day?-yes:", "")
          .replace("tee_time_on_departure_day?-yes", "Yes")
      )
    );
    $(".summary-data-more-than-one-round span").text(
      capitalizeFirstLetter(
        golf_request_data[2]
          .replace("more_than_one_round_per_day?-yes:", "")
          .replace("more_than_one_round_per_day?-yes", "Yes")
      )
    );
  });

  // =================
  // Process the form
  // =================
  $(".trip-planner-form-submit").on("click", function (event) {
    console.log("submit button clicked");
    event.preventDefault();

    var response = grecaptcha.getResponse();
    if (response.length == 0) {
      //reCaptcha not verified
      alert("Please check the reCAPTCHA box.");
      return false;
    } else if (
      $("#HomePhone").valid() &&
      $("#Address1").valid() &&
      $("#City").valid() &&
      $("#State").valid() &&
      $("#Country") &&
      $("#ZipCode").valid() &&
      $("#occasion").valid() &&
      $("#preferred_time").valid()
    ) {
      var course_type_data = [];
      $("input.course_type:checked").each(function (index, element) {
        course_type_data.push($(element).val());
      });
      if (course_type_data.length === 0) {
        course_type_data.push("No Courses Requested");
      }
      var lodging_type_data = [];
      $("input.lodging_type_picker:checked").each(function (index, element) {
        lodging_type_data.push($(element).val());
      });
      if (lodging_type_data.length === 0) {
        lodging_type_data.push("No Lodging Types Requested");
      }

      var golf_request_data = Object.values($('.golf-yes-no-wrapper input[type="radio"]:checked'))
        .slice(0, 3)
        .reduce(function (result, element) {
          var nameMap = {
            "tee_time_on_arrival_day?-yes": "Tee time on arrival day: ",
            "tee_time_on_departure_day?-yes": "Tee time on departure day: ",
            "more_than_one_round_per_day?-yes": "More than one round per day: ",
          };

          var val = nameMap[$(element).attr("name")] + $(element).val().replace($(element).attr("name"), "yes") + "; ";

          result = result + val;

          return result;
        }, "");

      var message =
        "Preferred time to be reached:" +
        $("#preferred_time option:selected").text() +
        "<br>Number in your Party: " +
        $("#Adults").val() +
        "<br>Lodging Preferences: " +
        lodging_type_data.toString() +
        "<br>Golf Request: " +
        golf_request_data +
        "<br>Course Preferences: " +
        course_type_data.toString() +
        "<br>Occasion: " +
        $("#occasion").val() +
        "<br>Rooms Requested: " +
        $("#rooms").val() +
        "<br>Beds Requested: " +
        $("#beds").val();
      // Append all the extra info into the message field
      $("#Message").val(function (i, text) {
        return (
          "Additional Comments:<br>---------------<br>" +
          text +
          "<br>---------------<br>Form Information:<br>---------------<br>" +
          message
        );
      });
      // console.log('Jeff Message=' + message);
      // console.log('#Message=' + $('#Message').val());
      // Proceed to the next step
      $(".trip-planner-step-4-wrapper").removeClass("trip-planner-inner-wrapper-active");
      // Submit the form to NAVIS with the updated Message field.
      // Go to the final setp.
      $(".trip-planner-step-5-wrapper").addClass("trip-planner-inner-wrapper-active");

      // Add the password
      $("<input />")
        .attr("type", "hidden")
        .attr("value", "14826")
        .attr("name", "account")
        .appendTo("#trip-planner-form");
      $("<input />")
        .attr("type", "hidden")
        .attr("value", "hb6c0hud8tjvme7awmxj")
        .attr("name", "password")
        .appendTo("#trip-planner-form");
      $("#trip-planner-form").submit();

      var lodging = lodging_type_data.map((item) => ({ name: item }));
      var courses = course_type_data.map((item) => ({ name: item }));

      function gtag() {
        dataLayer.push({
          event: "trip_planner_submission",
          lodging,
          courses,
        });
      }

      gtag("js", new Date());

      var gtagParams = new URL(jQuery('script[src*="googletagmanager"]').attr("src")).searchParams;

      var gtagID = gtagParams.get("id");

      gtag("config", gtagID);

      $("#trip-planner-form").trigger("reset");
    }
  });

  function filterRoomsWithSynxisApiData() {
    loader.show();
    hideAllRooms();

    var callAuthAPI = {
      url: "https://services-p1.synxis.com/v1/auth/token",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic UjBOUFRVMURRa1ZRVWs5RTpTVzFxZGsxQ1FUazJXRGhQSVUwL0lRPT0=",
      },
      data: JSON.stringify({
        GrantType: "CLIENT_CREDENTIALS",
        UserIPAddress: "127.0.0.1",
        UserType: "Hotel",
        BusinessContext: "Default",
      }),
    };

    $.ajax(callAuthAPI)
      .done(function (response) {
        var startDate = $("#CheckInDate").val();
        var endDate = $("#CheckOutDate").val();
        var guests = $("#Adults").val();
        var rooms = $("#rooms").val();

        // Production Credentials for Bandon Dunes Golf Resort
        var hotelAvailabilityEndpoint = "https://services-p1.synxis.com/v1/api/hotel/availability?adults=";
        var hotelId = "37338";
        var chainId = "29070";

        var callAvailabilityAPI = {
          url:
            hotelAvailabilityEndpoint +
            guests +
            "&content=full&primaryChannel=WEB&secondaryChannel=SYNXISWS_BE&numRooms=" +
            rooms +
            "&chainId=" +
            chainId +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&hotelId=" +
            hotelId,
          method: "GET",
          async: false,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Context: "WBSVC",
            Authorization: "Bearer " + response.access_token,
          },
        };

        $.ajax(callAvailabilityAPI)
          .done(function (response) {
            console.log("retrieved response from availability API: ", response);

            const errorCodes = ["ZeroFinalPrice", "Closed"];

            // if there is no availability
            if (
              (!Boolean(response.contentLists) &&
                response?.productAvailability?.LeastRestrictiveFailure?.ProductStatus === "NoAvailableInventory") ||
              (!Boolean(response.contentLists) &&
                response?.productAvailability?.LeastRestrictiveFailure?.ProductStatus ===
                  "MaxAdultOccupancyExceeded") ||
              response?.contentLists?.RoomList.length === 0
            ) {
              return handleNoAvailability();
            }

            // if there is an unexpected response
            else if (
              Boolean(response.ErrorCode) ||
              errorCodes.includes(response.productAvailability?.LeastRestrictiveFailure?.ProductStatus) ||
              response ===
                "upstream connect error or disconnect/reset before headers. retried and the latest reset reason: protocol error"
            ) {
              return handleSynxisError();
            }

            var availableRooms = response.contentLists?.RoomList.filter(function (room) {
              var bedsRequired = $("#beds").val();
              var roomsRequired = $("#rooms").val();
              // var availableBeds = room.Details.Bedding.reduce(
              //   (total, bedding) => total + bedding.quantity,
              //   0
              // );
              // return availableBeds >= bedsRequired;
              return room.Details.Bedding[0].Quantity >= bedsRequired / roomsRequired;
            }).map(function (room) {
              return room.Code;
            });

            console.log("availableRooms", availableRooms);

            if (availableRooms.length === 0) {
              return handleNoAvailability();
            }

            allRooms.each(function () {
              var room = $(this);
              var roomCodes = room.data("roomcode").split(",");

              roomCodes.forEach(function (roomCode) {
                if (availableRooms.includes(roomCode)) {
                  room.show();
                }
              });
            });

            // temporarily hide result counter
            $(".trip-planner-slider-counter").hide();

            // destroySlickSlider();
            toggleLodgingListVisibility(true);
            // initializeSlickSlider(availableRooms);

            // hide loader and show results
            loader.hide();
          })
          .fail(function (error) {
            console.error("error getting availability", error);
            return handleSynxisError();
          });
      })
      .fail(function (error) {
        console.error("error getting token", error);
        return handleSynxisError();
      });
  }

  function hideAllRooms() {
    allRooms.hide();
  }

  function handleNoAvailability() {
    console.log("synxis: there is no availability");
    loader.hide();
    toggleLodgingListVisibility(false);
    $(".trip-planner-synxis-error-availability").show();
  }

  function handleSynxisError() {
    console.log("synxis: there is an error");
    loader.hide();
    toggleLodgingListVisibility(false);
    $(".trip-planner-synxis-error-connection").show();
  }

  function handleSearchAgain() {
    console.log("searching again");
    toggleLodgingListVisibility(false);
    hideAllRooms();

    $(".trip-planner-step-3-wrapper").removeClass("trip-planner-inner-wrapper-active");

    // $('input[name="lodging_answers"]').prop("checked", false);
    // $('input[name="lodging_answers"]#yes-1').prop("checked", true);

    $(".lodging_type_picker").prop("checked", false);
    $(".trip-planner-single-lodging-wrapper .trip-planner-choice-label").removeClass("checked");

    $(".trip-planner-synxis-error-availability").hide();
    $(".trip-planner-synxis-error-connection").hide();
  }

  function toggleLodgingListVisibility(visible) {
    if (visible) {
      $(".trip-planner-step-2b-form-wrapper").css("visibility", "visible");
    } else {
      $(".trip-planner-step-2b-form-wrapper").css("visibility", "hidden");
    }
  }

  function setNeedLodging(needsLodging) {
    if (needsLodging) {
      console.log("setting lodging needed");
      $("input[name='lodging_answers']").prop("checked", false);
      $("input[name='lodging_answers']#yes-1").prop("checked", true);
      $("#rooms").parent().css("display", "block");
      $("#beds").parent().css("display", "block");
      $(".trip-planner-step-1b-options-wrapper .trip-planner-select-item-wrapper").removeClass("selected");
      $(".trip-planner-step-1b-options-wrapper .trip-planner-select-item-wrapper").first().addClass("selected");
      $(".trip-planner-radio-select").addClass("btn-transparent");
      $(".trip-planner-radio-select").first().removeClass("btn-transparent");
    } else {
      console.log("setting lodging not needed");
      $("input[name='lodging_answers']").prop("checked", false);
      $("input[name='lodging_answers']#no-2").prop("checked", true);
      $("#rooms").parent().css("display", "none");
      $("#beds").parent().css("display", "none");
      $(".trip-planner-step-1b-options-wrapper .trip-planner-select-item-wrapper").removeClass("selected");
      $(".trip-planner-step-1b-options-wrapper .trip-planner-select-item-wrapper").last().addClass("selected");
      $(".trip-planner-radio-select").addClass("btn-transparent");
      $(".trip-planner-radio-select").last().removeClass("btn-transparent");
    }
  }

  function destroySlickSlider() {
    if ($(".trip-planner-rooms-slider-wrapper.slick-initialized").length > 0) {
      $(".trip-planner-rooms-slider-wrapper").slick("destroy");
      $(".trip-planner-rooms-slider-wrapper").empty();
    }
  }

  function initializeSlickSlider(availableRooms = 4) {
    console.log("initializing slick slider");
    // =================
    // Trip Planner Rooms slider
    // =================
    var slidesToShow = availableRooms.length >= 4 ? 4 : availableRooms.length;

    $(".trip-planner-rooms-slider-wrapper").slick({
      arrows: true,
      dots: true,
      autoplay: false,
      adaptiveHeight: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: false,
      appendArrows: ".bd-trip-planner-slick-arrow-wrapper",
      prevArrow: ".bd-trip-planner-slick-arrow-wrapper .bd-trip-planner-slick-prev",
      nextArrow: ".bd-trip-planner-slick-arrow-wrapper .bd-trip-planner-slick-next",
      responsive: [
        {
          breakpoint: 600,
          settings: "unslick",
        },
      ],
    });
  }
}); /* end of as page load scripts */
